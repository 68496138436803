<template>
  <section>
    <v-form ref="form" v-model="valid" lazy-validation class="d-flex flex-column mb-4">

      <div class="d-flex justify-content-between" @click="openFileBottomSheet">
        <span class="my-1">{{ $t('Select video') }}</span>
        <v-btn color="primary" class="" rounded>{{ $t('Change') }}</v-btn>
      </div>

      <div id="previewContainer" class="imagePreviewWrapper">

        <vue-plyr ref="plyr">
          <video controls playsinline data-plyr-config='{ "ratio": "1:1" }' :src="previewVideo">
            Your browser does not support the video tag.
          </video>
        </vue-plyr>

      </div>

      <span class="my-1 d-block">{{ $t('Title') }}</span>
      <v-text-field outlined single-line v-model="this.video.title" type="text" :rules="titleRules" required counter="100" />

      <span class="my-1 d-block">{{ $t('Category') }}</span>
      <v-select :label="$t('Choose')" v-model="this.video.groups" outlined required single-line small-chips
        :loading="categoryLoading" multiple return-object :no-data-text="$t('No data')" :items="categoryList"
        item-text="value" item-value="id" :rules="[(v) => !!v]">
      </v-select>

      <span class="my-1 d-block">{{ $t('Description') }}</span>
      <v-textarea outlined v-model="this.video.description" type="text" counter="1000" :rules="descriptionRules" required />

      <div class="mt-1 text-center">
        <v-btn color="primary" large class="col-12" @click="validate()" :loading="submit_loading"
          :disabled="submit_loading">
          {{ $t('Submit') }}
        </v-btn>
      </div>
    </v-form>

    <vue-bottom-sheet ref="fileBottomSheet">
      <section class="d-flex flex-column align-items-center justify-content-center">
        <v-list class="w-100 pt-0 mt-auto" light shaped>
          <span class="text-center mb-1 d-block f14 font-weight-bold">{{ $t('File selection') }}</span>
          <input ref="videoInput" type="file" @input="pickVideo" accept="video/*" hidden>
          <v-list-item ripple @click="selectVideo">
            <v-list-item-icon class="my-0 align-self-center">
              <v-sheet color="#ECFFF4" elevation="0"
                class="d-flex align-items-center justify-content-center rounded-lg p-2">
                <iconly type="bulk" name="video" color="#148B48" />
              </v-sheet>
            </v-list-item-icon>
            <v-list-item-title tag="span" class="f14">
              <span v-if="isMobile()">{{ $t('Select video from gallery') }}</span>
              <span v-else>{{ $t('Select video') }}</span>
            </v-list-item-title>
          </v-list-item>

          <v-btn block color="primary" class="my-2" rounded>{{ $t('Close') }}
          </v-btn>
        </v-list>
      </section>
    </vue-bottom-sheet>

  </section>
</template>

<script>
import apiService from "@/http/ApiService";
import moment from "moment-jalaali";
import fa from "moment/locale/fa";
moment.locale("fa", fa);
moment.loadPersian();

import * as blobUtil from 'blob-util'

export default {
  data() {
    return {
      video:{},

      submit_loading: false,
      categoryLoading: true,
      typeLoading: true,
      valid: false,

      previewImage: null,
      previewVideo: null,
      categoryList: [],
      
      videoName: null,
      videoExtension: null,
      
      imageName: null,
      imageExtension: null,

      videoFile: null,

      fileChanged: false,

      titleRules: [
        v => !!v,
        v => (v && v.length <= 100) || this.$t("The maximum length is 100 characters")
      ],

      descriptionRules: [
        v => !!v,
        v => (v && v.length <= 1000) || this.$t("The maximum length is 1000 characters")
      ],

    }
  },
  methods: {
    async pickVideo() {
      let input = this.$refs.videoInput
      let file = input.files
      if (file && file[0]) {

        this.previewVideo = URL.createObjectURL(file[0]);

        let reader = new FileReader
        reader.onload = e => {
          this.videoFile = e.target.result
          this.fileChanged = true
        }
        reader.readAsDataURL(file[0]) 

        this.previewImage = await this.getVideoCover(file[0], 0.5);

        const lastDot = file[0].name.lastIndexOf('.');
        const fileName = file[0].name.substring(0, lastDot);

        this.videoName = fileName
        this.videoExtension = file[0].name.substring(lastDot + 1);
        
        this.imageName =  fileName + "_cover";
        this.imageExtension = "jpeg";

        

        this.closeExamBottomSheet()
        this.$emit('input', file[0])
      }
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    openFileBottomSheet() {
      this.$refs.fileBottomSheet.open();
    },
    closeExamBottomSheet() {
      this.$refs.fileBottomSheet.close();
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.submitForm();
      }
    },
    getAllCategories() {
      apiService.getActivities()
        .then((response) => {
          this.categoryList = response.data;
          this.categoryLoading = false;
        })
        .catch((err) => {
          this.$swal({
            icon: "warning",
            text: err ? err.response.data.detail : this.$t("Error"),
            confirmButtonText: this.$t('OK'),
          })
            .then((result) => {
              if (result.isConfirmed) {
                this.$router.go(-1);
              }
            });

        });
    },
    selectVideo() {
      this.$refs.videoInput.click()
    },

    submitForm() {
      if (!this.previewImage) {
        this.$swal({
          icon: "warning",
          text: this.$t("No video selected. Please select an video"),
          confirmButtonText: this.$t('OK'),
        });

        return;
      }
      this.submit_loading = true;

      if (this.fileChanged) {
        this.uploadCoverImage();
      }
      else {
        this.updateContent();
      }
    },
    getVideoCover(file, seekTo = 0.0) {
      console.log("getting video cover for file: ", file);
      return new Promise((resolve, reject) => {
        // load the file to a video player
        const videoPlayer = document.createElement('video');
        videoPlayer.setAttribute('src', URL.createObjectURL(file));
        videoPlayer.load();
        videoPlayer.addEventListener('error', (ex) => {
          reject("error when loading video file", ex);
        });
        // load metadata of the video to get video duration and dimensions
        videoPlayer.addEventListener('loadedmetadata', () => {
          // seek to user defined timestamp (in seconds) if possible
          if (videoPlayer.duration < seekTo) {
            reject("video is too short.");
            return;
          }
          // delay seeking or else 'seeked' event won't fire on Safari
          setTimeout(() => {
            videoPlayer.currentTime = seekTo;
          }, 200);
          // extract video thumbnail once seeking is complete
          videoPlayer.addEventListener('seeked', () => {
            console.log('video is now paused at %ss.', seekTo);
            // define a canvas to have the same dimension as the video
            const canvas = document.createElement("canvas");
            canvas.width = videoPlayer.videoWidth;
            canvas.height = videoPlayer.videoHeight;
            // draw the video frame to canvas
            const ctx = canvas.getContext("2d");
            ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
            // return the canvas image as a blob
            ctx.canvas.toBlob(
              blob => {
                resolve(blob);
              },
              "image/jpeg",
              1 /* quality */
            );
          });
        });
      });
    },
    uploadImage() {
      var blob = blobUtil.dataURLToBlob(this.previewImage)
      apiService
        .upload("POST", blob, this.imageName+"."+this.imageExtension, (event) => {
          this.progress = Math.round((100 * event.loaded) / event.total);
        })
        .then((response) => {
          this.uploadedPicUrl = response.data.originalFileDownloadUrl;
          this.uploadedThumbnailPicUrl = response.data.thumbnailPicDownloadUrl;

          var contentDTO = {
            groups: this.groups,
            description: this.description,
            title: this.title,
            publisher: this.$store.getters.mobileNumber,
            picture : this.uploadedPicUrl,
            thumbnailUrl : this.uploadedThumbnailPicUrl
          };

          this.addContent("POST", contentDTO);
        })
        .catch(() => {
          this.submit_loading = false;
          this.$swal({
            icon: "warning",
            text: this.$t('Could not upload the file!'),
            confirmButtonText: this.$t("OK"),
          });
        });
    },
    uploadCoverImage() {
      apiService
        .upload("POST", this.previewImage, this.imageName+"."+this.imageExtension, (event) => {
          this.progress = Math.round((100 * event.loaded) / event.total);
        })
        .then((response) => {
          
          this.video.picture = response.data.originalFileDownloadUrl
          this.video.thumbnailUrl = response.data.thumbnailPicDownloadUrl

          this.uploadVideo();
        })
        .catch(() => {
          this.submit_loading = false;
          this.$swal({
            icon: "warning",
            text: this.$t('Could not upload the file!'),
            confirmButtonText: this.$t("OK"),
          });
        });
    },
    uploadVideo() {
      var blob = blobUtil.dataURLToBlob(this.videoFile)
      apiService
        .upload("VIDEO", blob, this.videoName+"."+this.videoExtension, (event) => {
          this.progress = Math.round((100 * event.loaded) / event.total);
        })
        .then((response) => {

          this.video.url = response.data.originalFileDownloadUrl

          this.updateContent();
        })
        .catch(() => {
          this.submit_loading = false;
          this.$swal({
            icon: "warning",
            text: this.$t('Could not upload the file!'),
            confirmButtonText: this.$t("OK"),
          });
        });
    },
    updateContent() {
   
        apiService
          .updateVideo(this.video)
          .then(() => {
            this.submit_loading = false;
            this.$swal({
              icon: "success",
              text: this.$t('The content was added successfully'),
              confirmButtonText: this.$t('OK'),
            })
              .then((result) => {
                if (result.isConfirmed) {
                  this.$router.go(-1);
                }
              });
          })
          .catch((err) => {
            this.submit_loading = false;
            this.$swal({
              icon: "warning",
              text: err ? err.response.data.detail : this.$t("Error"),
              confirmButtonText: this.$t('OK'),
            });
          });

    }
  },
  beforeMount() {
    this.$store.commit('update_backActive', true);
    this.$store.commit('update_helloActive', false);
    this.$store.commit('update_threeDotsActive', false);
    this.$store.commit('update_notificationActive', true);
    this.$store.commit('update_toolbarTitleActive', true);
  },
  created() {
    this.getAllCategories();
  }
}
</script>
<style scoped lang="scss">
.imagePreviewWrapper {
  width: 300px;
  height: 300px;
  display: block;
  margin: 0 auto 30px;
  background-size: cover;
  background-position: center center;
}

.img-preview {
  width: 300px;
  height: 300px;
  aspect-ratio: 1 / 1;
  object-fit: cover;
}
</style>